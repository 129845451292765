import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['attachmentContainer', 'uploadButton'];

  static values = {
    disableUpload: Boolean
  };

  connect() {
    this.disableUploadButton();
  }

  updateAttachments(event) {
    const { files, multiple } = event.target;

    for (const file of files) {
      if (multiple) {
        this.attachmentContainerTarget.innerHTML += `<div class="mt-2">${file.name}</div>`;
      } else {
        this.attachmentContainerTarget.innerHTML = `<div class="mt-2">${file.name}</div>`;
      }
    }
    this.disableUploadButton();
  }

  disableUploadButton() {
    if (!this.disableUploadValue) { return; }

    const uploadButton = this.uploadButtonTarget;

    if (this.attachmentContainerTarget.children.length === 0) {
      uploadButton.disabled = true;
    } else {
      uploadButton.disabled = false;
    }
  }
}
