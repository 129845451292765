import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'emailInput',
    'numberInput',
    'addressInput',
    'emailPlaceholder',
    'numberPlaceholder',
    'addressPlaceholder'
  ];

  static values = {
    emailValue: String,
    numberValue: String,
    addressValue: String
  };

  initialize() {
    this.emailValue = 'info@compendium360.com';
    this.numberValue = '855-968-6372';
    this.addressValue = 'PO Box 763, Idyllwild, CA 92549';
  }

  infoModal() {
    this.updatePlaceholder('email');
    this.updatePlaceholder('number');
    this.updatePlaceholder('address');

    const previewModalElement = document.getElementById('preview-contact-info-modal');

    if (previewModalElement && previewModalElement.modal) {
      previewModalElement.modal.show();
    }
  }

  updatePlaceholder(targetName) {
    const inputTarget = this[`${targetName}InputTarget`];
    const placeholderTarget = this[`${targetName}PlaceholderTarget`];
    const value = this[`${targetName}Value`];

    placeholderTarget.innerHTML = inputTarget.value || value;
  }
}
