import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['productDiv', 'iconDown', 'iconRight'];

  toggleProducts() {
    this.productDivTarget.classList.toggle('d-none');
    this.iconDownTarget.classList.toggle('d-none');
    this.iconRightTarget.classList.toggle('d-none');
  }
}
