import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'stripeId',
    'annualPricingInput',
    'monthlyPricingInput',
    'permittedField',
    'payPerUserSwitch'
  ];

  connect() {
    this.toggleInputs();
  }

  toggleInputs() {
    const switchValue = this.payPerUserSwitchTarget.checked;

    this.annualPricingInputTargets.forEach(input => {
      input.disabled = switchValue;
    });

    this.monthlyPricingInputTargets.forEach(input => {
      input.disabled = switchValue;
    });

    this.permittedFieldTargets.forEach(input => {
      input.readOnly = !switchValue;
    });
  }

  payPerUserSwitchChanged() {
    this.toggleInputs();
  }
}
